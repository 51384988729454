<template>
  <div id="MyProfile">
    <div class="MainContainer">
      <div class="typeTitle">我的帐户</div>

      <div class="lineRow"> </div>

      <div class="infoContainer">
        <div class="left">
          <div class="block"> 个人信息 </div>
        </div>
        <div class="right">
          <div class="infoContainer">
            <div class="titleBlock"> 帐号 (Email) : </div>
            <div class="optionBlock"> {{ $store.state.loginData.loginMail }} </div>
          </div>
          <div class="infoContainer">
            <div class="titleBlock"> <div class="text">密码： </div></div>
            <div class="optionBlock">
              <div class="editBtn" @click="editHandler"> 编辑 </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="isShowDialog" center :show-close="false" width="90%">
      <div>
        <el-form
          :model="formData"
          :hide-required-asterisk="true"
          ref="formData"
          label-width="100px"
          class="formCSS"
          label-position="top"
        >
          <el-form-item
            prop="oldPassword"
            label="当前密码"
            :rules="[
              { required: true, message: '请输入当前密码', trigger: 'blur' },
              { validator: validateOldPass, trigger: 'blur' },
            ]"
          >
            <el-input v-model="formData.oldPassword"></el-input>
          </el-form-item>

          <el-form-item
            label="新密码"
            prop="password"
            class="PWDBlock"
            :rules="[{ required: true, message: '请输入新密码', trigger: 'blur' }]"
          >
            <el-input type="password" v-model="formData.password" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item
            label="重新输入新密码"
            prop="checkPassword"
            class="PWDBlock"
            :rules="[
              { required: true, message: '重新输入新密码', trigger: 'blur' },
              { validator: validateCheckPass, trigger: 'blur' },
            ]"
          >
            <el-input
              type="password"
              v-model="formData.checkPassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="saveBtn" @click="submitForm"> 保存更改 </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MyProfile',
    data() {
      return {
        isShowDialog: false,
        dialogData: {},
        formData: {
          oldPassword: '',
          password: '',
          checkPassword: '',
        },
      };
    },
    created() {
      if (!this.$store.state.loginData.isLogin) {
        this.$router.replace({ name: 'Login' });
      }
    },
    computed: {
      currentEmail() {
        return this.$store.state.loginData.loginMail;
      },
    },
    methods: {
      validateCheckPass(rule, value, callback) {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.formData.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      },
      async validateOldPass(rule, value, callback) {
        const findRes = await this.$store
          .dispatch('findAccountDataByEmail', this.currentEmail)
          .catch(this.$autoCatch);
        if (value === '') {
          callback(new Error('请输入当前密码'));
        } else if (value !== findRes.password) {
          callback(new Error('与旧密码不一致'));
        } else {
          callback();
        }
      },
      editHandler() {
        this.isShowDialog = true;
        this.formData = {
          oldPassword: '',
          password: '',
          checkPassword: '',
        };
      },
      submitForm(formName) {
        this.$refs.formData.validate((valid) => {
          if (valid) {
            this.$store.commit('setLoading', true);
            setTimeout(() => {
              this.$store
                .dispatch('setAccountDataByEmail', {
                  email: this.currentEmail,
                  newPassword: this.formData.password,
                })
                .catch(this.$autoCatch);
              this.$store.commit('setLoading', false);
              this.isShowDialog = false;
              this.$notify({
                title: '成功',
                message: '密码已变更',
                type: 'success',
              });
            }, 500);
          } else {
            return false;
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  #MyProfile {
    .el-form-item__error {
      padding-top: 0px;
      margin-top: -6px;
    }
    .el-dialog__wrapper {
      .el-dialog {
        max-width: 450px !important;
      }
    }
    .el-dialog__body {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .el-form-item:last-child {
      margin-bottom: 0px;
    }
    .saveBtn {
      @media screen and(max-width:768px) {
        font-size: 16px !important;
        padding: 10px 0 !important;
      }
    }
  }
</style>
<style lang="scss" scoped>
  #MyProfile {
    background: url('~@/assets/bg.png');
    .MainContainer {
      $OutContainerPadding: 30px;
      @include setMaxWidth;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background: white;
      padding: 0px $OutContainerPadding;
      padding-top: 60px;
      padding-bottom: 500px;
      overflow-x: auto;
      @media screen and(max-width:768px) {
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .typeTitle {
        font-size: 2rem;
        font-weight: bold;
        padding: 40px 0 15px 0;
        @media screen and(max-width:768px) {
          padding: 15px 0 10px 0;
          font-size: 16px;
        }
      }

      .lineRow {
        width: calc(100% - $OutContainerPadding);
        height: 2px;
        background: #dbdbdb80 0% 0% no-repeat padding-box;
      }

      .infoContainer {
        margin-top: 40px;
        display: flex;
        @media screen and(max-width:768px) {
          flex-wrap: wrap;
        }
        .left {
          margin-right: 110px;
          width: 25%;
          @media screen and(max-width:768px) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 30px;
            margin: 0 auto;
          }
          .block {
            max-width: 260px;
            width: 100%;
            padding: 16px 35px;
            color: #153892;
            border: 1px solid #153892;
            margin-bottom: 12px;
            font-weight: bold;
            text-align: center;
          }
        }
        .right {
          width: calc(80% - 110px);
          @media screen and(max-width:768px) {
            width: 100%;
          }
          .infoContainer {
            width: 100%;
            display: flex;
            font-size: 1.25rem;
            @media screen and(max-width:768px) {
              flex-wrap: wrap;
            }
            .titleBlock {
              width: 40%;
              display: flex;
              align-items: center;
              @media screen and(max-width:768px) {
                margin-right: 10px;
              }
            }
            .optionBlock {
              width: calc(60% - 10px);

              .editBtn {
                background: transparent linear-gradient(180deg, #4369c9 0%, #153892 100%) 0% 0%
                  no-repeat padding-box;
                border: 1px solid #173b95;
                border-radius: 5px;
                width: 120px;
                color: white;
                text-align: center;
                padding: 6px 0;
                cursor: pointer;
                &:hover {
                  background: transparent linear-gradient(180deg, #5a79c7 0%, #2b4892 100%) 0% 0%
                    no-repeat padding-box;
                }
              }
            }
          }
        }
      }
    }

    .saveBtn {
      margin: 30px auto;
      background: transparent linear-gradient(180deg, #4369c9 0%, #153892 100%) 0% 0% no-repeat
        padding-box;
      border: 1px solid #173b95;
      border-radius: 10px;
      width: 180px;
      color: white;
      text-align: center;
      padding: 12px 0;
      cursor: pointer;
      font-size: 1.3rem;
      @media screen and(max-width:768px) {
        margin: 10px auto;
      }
      &:hover {
        background: transparent linear-gradient(180deg, #5a79c7 0%, #2b4892 100%) 0% 0% no-repeat
          padding-box;
      }
    }
    .el-dialog {
      .el-form {
        .el-form-item {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        .el-form-item::after,
        .el-form-item::before {
          content: unset;
        }
      }
    }
  }
</style>
